import PresenceControlAgent from 'ApiAgents/Settings/PresenceControlAgent';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import {
  get, omit, isArray
} from 'lodash';
import {
  MAX_REDUCE_END_TIME,
  MAX_REDUCE_START_TIME
} from 'Shared/constants/presenceControlDefaults';


const presenceControlAgent = new PresenceControlAgent();

export class PresenceControlStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable list = [];

  @observable formData = {};

  @observable isLoading = false;

  @observable isListLoaded = false;

  @observable isItemLoading = false;

  @observable isSaving = false;

  @observable isUpdating = false;

  @action loadList = async () => {
    this.isLoading = true;
    this.isListLoaded = false;
    try {
      const data = await presenceControlAgent.loadList();
      let list = get(data, 'items');
      if (isArray(list)) {
        list = list.map(item => ({ ...item, isUpdating: false }));
      }
      runInAction(() => {
        this.list = list;
        this.isLoading = false;
        this.isListLoaded = true;
      });
    } catch (error) {
      console.log('ERROR LOAD PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.list = [];
        this.isLoading = false;
        this.isListLoaded = true;
      });
      throw new Error(error);
    }
  }

  @action loadById = async (id) => {
    this.isItemLoading = true;
    try {
      const data = await presenceControlAgent.loadById(id);

      const adaptedData = { maxReduceStartTime: MAX_REDUCE_START_TIME, maxReduceEndTime: MAX_REDUCE_END_TIME, ...data };
      if (typeof adaptedData.maxReduceStartTime === 'number') {
        adaptedData.maxReduceStartTime = adaptedData.maxReduceStartTime.toString();
      }
      if (typeof adaptedData.maxReduceEndTime === 'number') {
        adaptedData.maxReduceEndTime = adaptedData.maxReduceEndTime.toString();
      }

      runInAction(() => {
        this.formData = adaptedData;
        this.isItemLoading = false;
      });
    } catch (error) {
      console.log('ERROR LOAD PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.isItemLoading = false;
      });
      throw new Error(error);
    }
  }

  @action update = async () => {
    this.isUpdating = true;
    const adaptedData = omit(this.formData, 'isUpdating');
    adaptedData.maxReduceStartTime = +adaptedData.maxReduceStartTime;
    adaptedData.maxReduceEndTime = +adaptedData.maxReduceEndTime;

    try {
      const res = await presenceControlAgent.update(adaptedData);

      runInAction(() => {
        this.isUpdating = false;
        this.formData = {};
      });
      return { res };
    } catch (error) {
      console.log('ERROR LOAD PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.isUpdating = false;
        this.formData = {};
      });
      return { error };
    }
  }

  @action onAlternativeFieldUpdate = async (id, value) => {
    const fieldIndex = this.list.findIndex(i => i.id === id);
    if (fieldIndex < 0) return;
    try {
      runInAction(() => {
        this.list[fieldIndex].isUpdating = true;
      });
      const {
        id: fieldId, name, distance, mapLink
      } = this.list[fieldIndex];
      const result = await presenceControlAgent.update({
        id: fieldId, name, distance, mapLink, isAlternativeEnabled: value
      });
      const updatedIsAlternativeEnabled = get(result, 'isAlternativeEnabled', false);
      runInAction(() => {
        this.list[fieldIndex].isUpdating = false;
        this.list[fieldIndex].isAlternativeEnabled = updatedIsAlternativeEnabled;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR ON ALTERNATIVE FIELD UPDATE: ', error);
      runInAction(() => {
        this.list[fieldIndex].isUpdating = false;
      });
      return { error };
    }
  }

  @action updateFormData = (field, value) => {
    this.formData[field] = value;
  };

  @action resetFormData = () => {
    this.formData = {};
  };
}

export default PresenceControlStore;
