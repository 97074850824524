import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  block: {
    display: 'flex',
    gap: 10,
  },
  column: {
    width: '50%',
  },
  distanceOptionRenderer: {
    marginTop: 0,
    marginBottom: 0,
  },
  unitLabel: {
    color: theme.palette.black30,
    marginLeft: 4,
  },
  switch: {
    marginTop: 10,
    marginBottom: 22,
    marginLeft: 16,
  },
}));

export default useStyles;
