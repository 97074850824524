import React, {
  forwardRef, useEffect, useState, useRef, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, get } from 'lodash';
import clsx from 'clsx';
import {
  Grid,
  Paper,
  Select,
  MenuItem,
  TableCell,
  Tooltip,
  IconButton,
} from '@material-ui/core/';
import { withTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab/';
import MaterialTable, { MTableBodyRow, MTableCell } from 'material-table';
import NoResultsBlock from 'Common/widgets/NoResultsBlock';
import {
  AutoSizer, List as VirtualList,
} from 'react-virtualized';

import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useTableBodyHeight from 'Src/utils/table/useTableBodyHeight';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

import TableLink from 'Common/components/TableLink';
import OverflowTip from 'Common/widgets/OverflowTip';

import theme from 'Src/theme';
import useStyles from './styles';


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const pageSizes = [10, 20, 30];
const [initialPageSize] = pageSizes;

const List = ({
  title,
  titleSingle,
  columns,
  data,
  isDataLoaded,
  onEdit,
  onRemove,
  onRowClick,
  onAssign,
  onPageChange,
  onPerPageChange,
  count,
  pageIndex,
  actions,
  options,
  localization,
  tableBodyHeight,
  withPageTabs,
  initialPage,
  initialPerPage,
  dataTestRowSuffixEntityName,
  withBoxShadow,
  isVisible,
  emptyDataSourceMessage,
  isVirtualized
}) => {
  const { t } = useTranslation();
  const computedBodyHeight = useTableBodyHeight({ withTableFooter: !!data.length, withPageTabs });
  const bodyHeight = tableBodyHeight || computedBodyHeight;
  const showEmptyBlock = isDataLoaded && isEmpty(data);
  const classes = useStyles({ showEmptyBlock, bodyHeight, isVirtualized });
  const [page, pageSet] = useState(initialPage);
  const [perPage, perPageSet] = useState(initialPerPage);

  const rootElementRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (!isVirtualized || !rootElementRef.current) return;

    const updateSize = () => {
      setHeight(rootElementRef.current.clientHeight);
      setWidth(rootElementRef.current.clientWidth);
    };

    // Используем ResizeObserver для отслеживания изменений размеров
    const resizeObserver = new ResizeObserver(updateSize);
    resizeObserver.observe(rootElementRef.current);

    // Устанавливаем начальные размеры
    updateSize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [isVirtualized, rootElementRef.current]);

  const listRef = useRef(null);


  const allActions = {
    edit: ({ actions: acts }) => ({
      icon: Edit,
      actionName: 'edit',
      tooltip: `${t('EDIT')} ${titleSingle}`,
      onClick: onEdit,
      ...(acts ? acts.edit : {})
    }),
    remove: ({ actions: acts }) => ({
      icon: DeleteOutline,
      actionName: 'remove',
      tooltip: `${t('DELETE')} ${titleSingle}`,
      onClick: onRemove,
      ...(acts ? acts.remove : {})
    }),
    assign: ({ actions: acts }) => ({
      icon: ExitToAppIcon,
      actionName: 'assign',
      tooltip: `${t('ASSIGN')} ${titleSingle}`,
      onClick: onAssign,
      ...(acts ? acts.assign : {})
    })
  };

  useEffect(() => {
    if (pageIndex > count && count > 0) {
      pageSet(count);
      onPageChange(count, perPage);
    }
  }, [pageIndex, count]);

  useEffect(() => {
    if (pageIndex >= INITIAL_PAGE_NUMBER && pageIndex !== page) {
      pageSet(pageIndex);
    }
  }, [pageIndex]);

  const handlePerPageChange = (event) => {
    const perPageValue = event.target.value;
    pageSet(1);
    perPageSet(() => perPageValue);
    onPerPageChange(1, perPageValue);
  };

  const handlePageChange = (event, pageNumber) => {
    pageSet(pageNumber);
    onPageChange(pageNumber, perPage);
  };


  // eslint-disable-next-line react/prop-types
  const renderPaging = ({ rowsPerPageOptions = [] } = {}) => {
    if (isEmpty(rowsPerPageOptions)) return null;
    return (
      <TableCell style={{ borderTop: '1px solid #f3f3f3' }}>
        <Grid container justify="flex-end" style={{ paddingRight: 30 }}>
          <Select
            value={perPage}
            onChange={handlePerPageChange}
            className={classes.pageSelect}
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem value={option} key={option}>
                {`${t('PRETEXT_FOR')} ${option}`}
              </MenuItem>
            ))}
          </Select>
          <Pagination
            showFirstButton
            showLastButton
            page={page}
            count={count}
            onChange={handlePageChange}
          />
        </Grid>
      </TableCell>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderActionButton = ({ action: { action } = {}, data: propsData } = {}) => {
    const params = action(propsData) || {};
    const {
      icon: ActionIcon, disabled, tooltip, onClick, actionName, hidden
    } = params;
    const isRemoveAction = actionName === 'remove';
    if (hidden) return null;
    const actionIcon = (
      <IconButton
        key={actionName}
        className={clsx(isRemoveAction && classes.removeButton)}
        disabled={disabled}
        size="medium"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(e, propsData);
        }}
      >
        <ActionIcon />
      </IconButton>
    );
    if (disabled) return actionIcon;
    return (
      <Tooltip
        title={tooltip}
        key={actionName}
      >
        <div>
          {actionIcon}
        </div>
      </Tooltip>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderActions = ({ actions: tableActions, data: tableData }) => (
    <div className={classes.actionsWrapper}>
      {tableActions.map(({ action } = {}) => renderActionButton({ action: { action }, data: tableData }))}
    </div>
  );

  const renderRow = (props) => {
    const { data: rowData = {} } = props;
    const dataTestRowSuffix = get(rowData, dataTestRowSuffixEntityName);
    return (
      <MTableBodyRow data-test={`tableRow_${dataTestRowSuffix}`} {...props} />
    );
  };

  const renderCell = (props) => {
    const { columnDef = {} } = props;
    const { field = '' } = columnDef;
    return (
      <MTableCell data-test={`tableCell_${field}`} {...props} />
    );
  };

  const renderResponsiveEllipsis = (text, field) => (
    <OverflowTip
      tooltipTitle={text}
      text={text}
      maxLine={2}
      data-test={field}
    />
  );

  const getCellContent = (render, field, rowData) => {
    if (render) return render(rowData, listRef) || t('INPUT_EMPTY_VALUE');
    return get(rowData, field) || t('INPUT_EMPTY_VALUE');
  };

  if (!isVisible) return null;

  const renderMaterialTable = () => (
    <MaterialTable
      style={{ ...!withBoxShadow && { boxShadow: 'none' } }}
      key={perPage}
      onRowClick={(e, rowData) => {
        if (onRowClick) onRowClick(e, rowData, { page, perPage });
      }}
      components={{
        Pagination: renderPaging,
        Actions: renderActions,
        ...(!isVirtualized && { Row: renderRow }),
        Cell: renderCell,
        ...(isVirtualized && {
          Body: ({
            renderData, options, onToggleDetailPanel, icons, actions, components, columns, getFieldValue, onRowClick // eslint-disable-line
          }) => (
            <AutoSizer disableHeight>
              {() => (
                <VirtualList
                  ref={listRef}
                  rowCount={renderData.length} // eslint-disable-line
                  height={height - theme.filterHeaderHeight}
                  width={width}
                  rowHeight={theme.virtualizedTableRowHeight}
                  rowRenderer={({ index, key, style }) => {
                    const rowData = renderData[index];
                    const dataTestRowSuffix = get(rowData, dataTestRowSuffixEntityName);
                    return (
                      <div key={key} style={{ ...style, display: 'table', tableLayout: 'fixed' }}>
                        <MTableBodyRow
                          style={style}
                          key={key}
                          index={index}
                          data-test={`tableRow_${dataTestRowSuffix}`}
                          data={rowData}
                          options={options}
                          onToggleDetailPanel={onToggleDetailPanel}
                          icons={icons}
                          actions={actions}
                          components={components}
                          columns={columns}
                          getFieldValue={getFieldValue}
                          onRowClick={onRowClick}
                        />
                      </div>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )
        }),
      }}
      className={classes.table}
      title={title || ''}
      icons={tableIcons}
      columns={columns.map((column) => {
        const {
          withEllipsis, mainLink, field, render
        } = column;
        if (mainLink) {
          return (
            {
              ...column,
              render: (rowData) => {
                const cellContent = getCellContent(render, field, rowData);
                return (
                  <TableLink
                    {...!withEllipsis && { 'data-test': field }}
                  >
                    {withEllipsis ? renderResponsiveEllipsis(cellContent, field) : cellContent}
                  </TableLink>
                );
              },
            }
          );
        }
        if (withEllipsis) {
          return (
            {
              ...column,
              render: rowData => renderResponsiveEllipsis(
                getCellContent(render, field, rowData), field
              ),
            }
          );
        }
        return {
          ...column,
          render: rowData => getCellContent(render, field, rowData)
        };
      },)}
      data={data}
      actions={actions.map(action => allActions[action])}
      options={{
        showEmptyDataSourceMessage: showEmptyBlock,
        paging: count > 0,
        sorting: false,
        search: false,
        draggable: false,
        paginationType: 'stepped',
        headerStyle: {
          lineHeight: '20px',
          color: '#a1a1a1',
        },
        actionsColumnIndex: -1,
        minBodyHeight: bodyHeight,
        maxBodyHeight: bodyHeight,
        toolbar: Boolean(title),
        pageSize: perPage || data.length,
        pageSizeOptions: pageSizes,
        emptyRowsWhenPaging: false,
        ...options,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: emptyDataSourceMessage || (
            <NoResultsBlock
              title={t('LIST_IS_EMPTY')}
              subTitle=" "
            />
          )
        },
        pagination: {
          labelRowsSelect: t('PAGES_COUNT_LABEL'),
          previousTooltip: t('PREVIOUS_PAGE'),
          nextTooltip: t('NEXT_PAGE'),
          firstTooltip: t('FIST_PAGE'),
          lastTooltip: t('LAST_PAGE'),
        },
        header: {
          actions: null,
        },
        ...localization,
      }}
    />
  );

  return (
    <div ref={rootElementRef} className={classes.root}>
      <Paper className={classes.paper}>
        {renderMaterialTable()}
      </Paper>
    </div>
  );
};

List.propTypes = {
  isVirtualized: PropTypes.bool, // !!! EXPERIMENTAL !!! PLEASE TEST WORK
  initialPage: PropTypes.number,
  initialPerPage: PropTypes.number,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  titleSingle: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onAssign: PropTypes.func,
  onRowClick: PropTypes.func,
  count: PropTypes.number,
  pageIndex: PropTypes.number,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPerPageChange: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      title: PropTypes.string.isRequired,
      isViewLink: PropTypes.bool
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({})
  ),
  isDataLoaded: PropTypes.bool,
  // eslint-disable-next-line
  localization: PropTypes.object,
  // eslint-disable-next-line
  options: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.string.isRequired
  ),
  tableBodyHeight: PropTypes.string,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
  }).isRequired,
  withPageTabs: PropTypes.bool,
  dataTestRowSuffixEntityName: PropTypes.string,
  columnDef: PropTypes.shape({
    field: PropTypes.string,
  }),
  withBoxShadow: PropTypes.bool,
  isVisible: PropTypes.bool,
  emptyDataSourceMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node])
};

List.defaultProps = {
  initialPage: INITIAL_PAGE_NUMBER,
  initialPerPage: initialPageSize,
  title: false,
  hasNextPage: false,
  hasPreviousPage: false,
  data: [],
  isDataLoaded: false,
  options: {},
  localization: {},
  count: 0,
  pageIndex: 1,
  onEdit: () => {},
  onRemove: () => {},
  onAssign: () => {},
  onRowClick: undefined,
  onPageChange: () => {},
  onPerPageChange: () => {},
  actions: ['edit', 'remove'],
  tableBodyHeight: undefined,
  withPageTabs: false,
  dataTestRowSuffixEntityName: '',
  columnDef: { field: '' },
  withBoxShadow: true,
  isVisible: true,
  emptyDataSourceMessage: null,
  isVirtualized: false
};

export default withTheme(List);
