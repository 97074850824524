const TRANSLATIONS_UA = {
  ABOUT_THE_PRESENCE_OF_THIS_REASON_CODE_IN_SAP_ERP: 'про наявність цього коду причини у SAP ERP',
  ACTIONS_BY_TAG: 'Дії по мітці',
  ACTIONS: 'Дії',
  ACTIVE_KEY: 'Активний ключ',
  ACTIVE_PLURAL: 'Активні',
  ACTUAL_LEAD_TIME: 'Фактичний час виконання',
  ACTUAL_TIME: 'Фактичний час',
  ACTUAL: 'Актуальні',
  ADD_ADDITIONAL_OPERATION: 'Введіть додаткову операцію',
  ADD_CURRENT_USER_HELPER_TEXT_ADDITIONAL: 'Натисніть “Додати” та надайте користувачеві роль та атрибути.',
  ADD_CURRENT_USER_HELPER_TEXT_MAIN: 'Цей користувач відсутній у системі.',
  ADD_CURRENT_USER: 'Додайте цього користувача',
  ADD_CUSTOM_TASK: 'Додати нестандартне завдання',
  ADD_EMPLOYEE: 'Додати співробітника',
  ADD_EQUIPMENT: 'Додати обладнання',
  ADD_ITEM: 'Додати запис',
  ADD_ONE_OR_MORE_POSITIONS: 'Додати одну або кілька позицій',
  ADD_OPERATION_KEY: 'Додати ключ операцій',
  ADD_OPERATION: 'Додати операцію',
  ADD_OPERATIONS: 'Додати операції',
  ADD_POSITION: 'Додати позицію',
  ADD_REASON: 'Додати причину',
  ADD_TAG: 'Додати мітку',
  ADD_USER: 'Додати користувача',
  ADD_WORKPLACE: 'Додати робоче місце',
  ADD: 'Додати',
  ADDING_AN_OPERATION: 'Додавання операції',
  ADDING_POSITIONS: 'Додавання позиції',
  ADDITIONAL_OPERATIONS: 'Додаткові операції',
  ALL_EMPLOYEES_LIST: 'Список всіх співробітників',
  ALL_PARAMS: 'Усі параметри',
  ALL_TASKS: 'Всі завдання',
  ALL: 'Всі',
  ALLOWED_VALUE: 'Допустиме значення',
  ALTERNATIVE_SCENARIO_OFF: 'Вимкнений альтернативний сценарій',
  ALTERNATIVE_SCENARIO_ON: 'Увімкнений альтернативний сценарій',
  ALTERNATIVE_SCENARIO_SHORT: 'Альт. Сценарій',
  ALTERNATIVE_SCENARIO: 'Альтернативний сценарій',
  AMOUNT_OF_PARALLEL_OPERATIONS_SHORT: 'Кіль-ть операцій для паралельного виконання',
  AMOUNT_OF_PARALLEL_OPERATIONS: 'Кількість операцій для паралельного виконання',
  AN_URGENT_TASK_WILL_BE_CREATED_BASED_ON_THE_DEFECT: 'За дефектом буде створене Актуальне завдання.',
  APPLY: 'Застосувати',
  APPOINTMENT_OF_THE_PERFORMER: 'Призначення виконавця',
  APPROVE_A_TASK: 'Узгодження завдання',
  APPROVE: 'Узгодити',
  APPROVERS_COMMENTS: 'Коментарі узгоджуючих',
  ARCHIVE: 'Архів',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_TASK_ROUTE: 'Впевнені, що бажаєте видалити маршрут?',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TASK: 'Впевнені, що бажаєте видалити завдання?',
  ASSIGN_PERFORMER: 'Призначити виконавця',
  ASSIGN_PERFORMERS: 'Призначити виконавців',
  ASSIGN_PRODUCER: 'Призначити виробником',
  ASSIGN_RESPONSIBLE: 'Призначити відповідальним',
  ASSIGN: 'Призначити',
  ASSIGNED_ENTITY: 'Призначено',
  ASSIGNED: 'Призначені',
  ASSIGNEES: 'Виконавці завдання',
  ATTENTION_TITLE: 'Увага!',
  AUART_TYPE: 'Вид замовлення (AUART)',
  AUTHOR: 'Автор',
  AUTO_TASKS: 'Завдання (автоматичні)',
  AUTOMATED_TASK_NAME: 'Назва автозавдання',
  AUTOMATED_TASK_SUCCESSFULLY_CREATED: 'Автозавдання успішно створене',
  AUTOMATIC_TASK_CREATION_CONFIGURATIONS: 'Конфигурації автоматичного створення завдання',
  AUTOTASK: 'Автозавдання',
  AUTOTASKS: 'Автозавдання',
  BLOCKED_USER_SUBTITLE: 'Зверніться до адміністратора.',
  BLOCKED_USER_TITLE: 'На жаль у Вас немає доступу',
  BLOCK: 'Блок',
  BLOCKED_PLURAL: 'Заблоковані',
  BRIGADE_COMPOSITION: 'Склад бригади (мінімум 2 людини)',
  BRIGADE_CREATION: 'Створення бригади',
  BRIGADE_HAS_ASSIGNED_TASK: 'На бригаду призначено завдання',
  BRIGADE_STAFF: 'Склад бригади',
  BRIGADE_SUCCESSFULLY_CREATED: 'Бригада успішно створена!',
  BRIGADE_SUCCESSFULLY_REMOVED: 'Бригада успішно видалена!',
  BRIGADE_TITLE_ACCUSATIVE: 'бригаду',
  BRIGADE_TITLE_GENITIVE: 'бригади',
  BRIGADE_TITLE: 'Назва бригади',
  BRIGADE: 'Бригада',
  BRIGADES: 'Бригади',
  BUILD_REPORT: 'Створити рапортичку',
  CANCEL: 'Відмінити',
  CANCELED: 'Відхилені',
  CANCELLATION: 'скасувати',
  CANT_CHANGE_STATE: 'Зміна стану неможлива.',
  CANT_CREATE_TASK_ROUTE: 'Створення маршруту неможливе.',
  CANT_CREATE: 'Створення неможливе.',
  CANT_DELETE_BRIGADE: 'Не вдалось видалити бригаду',
  CANT_DELETE_CHECKPOINT: 'Не вдалось видалити мітку',
  CANT_DELETE_INSPECTION_INVALID_DATE: 'Настала дата виконання. Обхід може знаходитися в роботі.',
  CANT_DELETE_INSPECTION_STATUS_CHANGED: 'Даний обхід змінив статус. Оновіть сторінку.',
  CANT_DELETE_INSPECTION: 'Видалення обходу неможливе.',
  CANT_SAVE: 'Збереження неможливе.',
  CANT_UPDATE_TASK_ROUTE: 'Редагування маршруту неможливе.',
  CANT_UPDATE: 'Редагування неможливе.',
  CANT_VIEW_ROUTE: 'Неможливо відобразити маршрут.',
  CHANGE_PERFORMER: 'Змініти виконавця',
  CHANGE_THE_NAME_AND_SAVE_THE_TASK_AGAIN: 'Змініть назву й збережіть завдання повторно.',
  CHANGES_SUCCESSFULLY_APPLIED: 'Зміни успішно збережені',
  CHANGING_THE_POWER_UNIT_WILL_DELETE_THE_STORED_TAGS: 'Зміна енергоблоку призведе до видалення збережених міток.',
  CHECK_THE_CORRECTNESS_OF_THE_ENTERED_DATA: 'Перевірте  правильність введених даних',
  CHECKPOINT_NAME_ALREADY_EXISTS: 'Мітка з такою назвою вже існує.',
  CHECKPOINT_ON_PAUSE: 'Мітка на паузі',
  CHECKPOINT_REJECTED: 'Мітка відхилена',
  CHECKPOINT_SUCCESSFULLY_CREATED: 'Мітка успішно створена!',
  CHECKPOINT_SUCCESSFULLY_REMOVED: 'Мітка успішно видалена!',
  CHOOSE_BRIGADE: 'Оберіть бригаду',
  CHOOSE_BUSINESS_TYPE_FOR_FURTHER_WORK: '***',
  CHOOSE_CATEGORY_TO_VIEW: 'Оберіть категорію для прегляду',
  CHOOSE_CHECKPOINT: 'Вкажіть мітку',
  CHOOSE_DEPARTMENT_AND_ROUTE: 'Вкажіть ділянку й маршрут для обходу',
  CHOOSE_DEPARTMENT: 'Оберіть ділянку',
  CHOOSE_EMPLOYEE_FROM_LIST: 'Оберіть співробітників зі списку',
  CHOOSE_EMPLOYEE: 'Оберіть виконавця',
  CHOOSE_EQUIPMENT_FROM_LIST: 'Оберіть обладнання зі списку',
  CHOOSE_EQUIPMENT_UNIT_FROM_LIST: 'Оберіть вузол зі списку',
  CHOOSE_PERFORMERS: 'Оберіть виконавців',
  CHOOSE_POWER_UNIT: 'Вкажіть енергоблок',
  CHOOSE_REASON: 'Виберіть причину',
  CHOOSE_RESPONSIBLE: 'Оберіть виконавця!',
  CHOOSE_ROUTE: 'Оберіть маршрут для обходу',
  CHOOSE_TECHNICAL_PLACE: 'Оберіть технічне місце',
  CHOOSE: 'Вкажіть',
  CHOSEN_EMPLOYEES_LIST: 'Список вибраних співробітників',
  CHOSEN_EQUIPMENT: 'Вибране обладнання',
  CLEAR_NESTING: 'Очистити вкладеність',
  CLEAR: 'Очистити',
  CLOSE: 'Закрити',
  CODE_IS_PRESENT_BUT_NOT_SCANNED: 'Код присутній але не сканується',
  CODE: 'Код',
  COLLAPSE_ALL: 'Розкрити всі',
  COLLAPSE: 'Згорнути',
  COMMENT: 'Коментар',
  COMMENTS: 'Коментарі',
  COMMON: 'Загальні',
  COMPLETED_ENTITY: 'Виконано',
  COMPLETED_INSPECTION: 'Звіт завершених обходів за період',
  COMPLETED_PARTICIPLE: 'Завершено',
  COMPLETED: 'Завершені',
  COMPLETION_DATE_SHORT: 'Дата завершення',
  COMPLETION_DATE: 'Дата закінчення виконання',
  CONFIRM_DELETING_AUTOTASK: 'Після видалення завдання автоматичне створення припинеться.',
  CONFIRM_DELETING_MESSAGE: 'Ви впевнені, що бажаєте видалити',
  CONFIRM_DELETING_PARAMETER_MESSAGE: 'Бажаєте видалити даний параметр?',
  CONFIRM_INFINITIVE: 'Підтвердіть',
  CONFIRM_MATERIAL_VALUES: 'Узгодити ТМЦ',
  CONFIRM: 'Підтвердити',
  CONFIRMATION_OF_PRESENCE_OR_ABSENCE: 'Підтвердження наяв./відсут.',
  CONFIRMED: 'Узгоджена',
  CONTROL_RESULT: 'Результат контроля',
  CONTROL: 'Контроль',
  CONTROLLER: 'Контролер',
  COPIED: 'Скопійовано!',
  COPY_TAG_CODE: 'Копіювати код мітки',
  CREATE_A_TASK: 'Створити завдання',
  CREATE_AUTOTASK: 'Створити автозавдання',
  CREATE_BRIGADE_ACTION: 'Створити бригаду',
  CREATE_BRIGADE_WITH_BUTTON: 'Створіть бригаду за допомогою кнопки “Створити бригаду”',
  CREATE_INSPECTION: 'Створити обхід',
  CREATE_PLANNER_TASK: 'Створити завдання',
  CREATE_REPORT: 'Створити рапортичку',
  CREATE_ROUTE: 'Створити маршрут',
  CREATE_TAG: 'Створити мітку',
  CREATE_VOLUME_PARAMETER: 'Створіть параметри об`єма!',
  CREATE: 'Створити',
  CREATED_BRIGADE: 'Створена бригада',
  CREATED_TASK_STATUS: 'Статус створеного завдання',
  CREATED_TASK: 'Створене завдання',
  CREATING_ITEM: 'Додавання запису',
  CREATION_DATE_TIME: 'Дата/час створення',
  CUSTOM_TASK: 'Нестандартне завдання',
  DAILY: 'Щодня',
  DATA_TYPE: 'Тип даних',
  DATE_AND_TIME: 'Дата й час',
  DATE_CANNOT_BE_IN_FUTURE_TENSE: 'Дата не може бути в майбутньому',
  DATE_CANNOT_BE_IN_PAST_TENSE: 'Дата не може бути в минулому часі',
  DATE_COMMA_TIME: 'Дата час',
  DATE_OF_COMPLETION: 'Дата виконання',
  DATE_OF_CREATION: 'Дата створення',
  DATE: 'Дата',
  DEFECT_ATRIBUTES: 'Атрибути дефекту',
  DEFECT_CODE_NAME: 'Код несправності',
  DEFECT_CODE: 'Код дефекту',
  DEFECT_DATE: 'Дата й час виявлення',
  DEFECT_EDITING: 'Редагування дефекту',
  DEFECT_FIXATION: 'Фіксація дефектів',
  DEFECT_GROUP: 'Група дефектів',
  DEFECT_NAME: 'Назва дефекту',
  DEFECT_REJECTED_SUCCESSFULLY: 'Дефект успішно відхилено!',
  DEFECT_RESOLVED_BY: 'Дефект усунув',
  DEFECT_SOURCE_STATUS: 'Статус джерела',
  DEFECT_SOURCE: 'Джерело дефекту',
  DEFECT_STATUS: 'Статус дефекту',
  DEFECT_SUCCESSFULLY_UPDATED: 'Дефект успішно змінений',
  DEFECT_WAS_SUCCESSFULLY_SENT_TO_WORK: 'Дефект успішно відправлений до роботи!',
  DEFECT_WILL_BE_SENT_TO_THE_REJECTED_TAB: 'Дефект буде відправлено на вкладку "Відхилені".',
  DEFECTS_FOR_RE_REGISTRATION: 'Дефекти для повторної реєстрації',
  DEFECTS_GROUP: 'Група дефектів ',
  DEFECTS: 'Дефекти',
  DELETE_CHECKPOINT: 'Видалити мітку',
  DELETE_EQUIPMENT_TASK: 'Видалити завдання',
  DELETE_EQUIPMENT: 'Видалити обладнання',
  DELETE_POSITION: 'Видалити позицію',
  DELETE: 'Видалити',
  DELETING_A_TASK_ROUTE: 'Видалення маршруту',
  DELETING_A_TASK: 'Видалення завдання',
  DELETING_CHECKPOINT_FROM_TASK: 'Видалення мітки із завдання',
  DELETING_EQUIPMENT_FROM_CHECKPOINT: 'Видалення обладнання з мітки',
  DELETING_PLANNER_TASK: 'Видалення завдання',
  DELETING_TASK_FROM_CHECKPOINT: 'Видалення завдання з мітки',
  DELETING: 'Видалення',
  DEPARTMENT: 'Ділянка',
  DEPARTMENT_ACCUSATIVE: 'Ділянку',
  DESCRIPTION: 'Опис',
  DETAILED_INSPECTION_REPORT: 'Детальний звіт завершених обходів за період',
  DEVICE_RECEIVED_DATE: 'Дата отримання завдання на моб.  пристрої',
  DEVICE_RECEIVED_TIME: 'Час отримання завдання на моб.  пристрої',
  DEVICE_TASK_END_DATE: 'Дата завершення завдання на моб.  пристрої',
  DEVICE_TASK_END_TIME: 'Час завершення завдання на моб. пристрої',
  DICTIONARIES: 'Довідники',
  DISPLAY_ON_DEVICE_BY_KEY: 'Відображення на пристрої за ключом',
  DO_YOU_WANT_TO_DELETE_WORKPLACE: 'Бажаєте видалити робоче місце?',
  DOCUMENTATION: 'Документація',
  DOWNLOAD_REPORT: 'Завантажити звіт',
  DOWNLOAD: 'Завантажити',
  COMPANY_NAME: 'ДТЕК',
  DUE_DATE: 'Дата виконання',
  DURATION: 'Тривалість',
  EDIT_CHECKPOINTS_TASKS: 'Редагувати завдання по мітці',
  EDIT_CUSTOM_TASK: 'Редагувати нестандартне завдання',
  EDIT_EQUIPMENT_TASK: 'Редагувати завдання по обладнанню',
  EDIT_EQUIPMENT_TASKS: 'Редагувати завдання по обладнанню',
  EDIT_POSITION: 'Редагувати позицію',
  EDIT: 'Редагувати',
  EDITING_A_TASK: 'Редагування завдання',
  EDITING_ITEM: 'Редагування запису',
  EDITING_POSITIONS: 'Редагування позиції',
  EDITING_PROHIBITED: 'Редагування заборонено.',
  EDITING: 'Редагування',
  EMPLOYEE_ADDING: 'Додати співробітників',
  EMPLOYEE: 'Виконавець',
  EMPTY_BRIGADE_STAFF_LIST: 'Список майстрів порожній!',
  EMPTY_BRIGADES_LIST: 'У вашому списку ще немає бригад',
  EMPTY_OPERATION_CONFIRMATION_HISTORY: 'Історія узгодження ТМЦ порожня',
  EMPTY_PERFORMERS_LIST_SUBTITLE: 'До обраної ділянки не закріплено жодного виконавця.',
  EMPTY_PERFORMERS_LIST: 'Список виконавців порожній',
  EMPTY_RESULTS_SUBTITLE: 'Ви можете додати новий запис.',
  EMPTY_RESULTS_TITLE: 'Записи відсутні',
  END_DATE_CANNOT_BE_LESS_THAN_START_DATE: 'Дата завершення не може бути менше за дату початку',
  END_DATE_CANNOT_BE_PAST_TENSE: 'Дата завершення не може бути минулим часом',
  END_DATE: 'Дата завершення',
  ENTER_CODE_OR_DEFECT_NAME: 'Введіть код або назву дефекту',
  ENTER_REJECTION_REASON_BY_CONTROLLER: 'Вкажіть причину відхилення завдання контролером',
  ENTER_REJECTION_REASON: 'Вкажіть причину відхилення завдання',
  ENTER_REQUIRED_MEASUREMENT_UNIT_NAME: 'Введіть назву необхідної ОВ',
  ENTER_THE_NAME_OR_EMAIL: 'Введіть ПІБ або пошту співробітника...',
  ENTER_THE_OPERATION_NAME: 'Введіть додаткову операцію',
  ENTERPRISE: 'Підприємство',
  EQUIPMENT_ADDING: 'Додавання обладнання',
  EQUIPMENT_AND_TYPE_OF_REPAIR: 'Обладнання й вид ремонту',
  EQUIPMENT_CHOISE: 'Вибір обладнання',
  EQUIPMENT_EDITING: 'Редагування обладнання',
  EQUIPMENT_ENTITY_SHORT: 'ОО',
  EQUIPMENT_LEVEL: 'ТМ Рівень',
  EQUIPMENT_UNITS: 'Вузли',
  EQUIPMENT: 'Обладнання',
  EQUIPMENT_ACCUSATIVE: 'Обладнання',
  ERROR_DELETE_WORKPLACE: 'Помилка видалення робочого місця',
  ERROR: 'Помилка',
  EXECUTION_APPROVAL_STATUS: 'Статус погодження виконання',
  EXECUTION_PERIOD: 'Період виконання',
  EXECUTION_RESULT: 'Результат виконання',
  EXECUTION_TIME_MIN: 'Час виконання. (хв)',
  EXISTING_EQUIPMENT: 'Існуюче обладнання',
  EXISTING_PARAMETER_NAME: 'Назва параметру вже існує',
  EXIT: 'Вийти',
  EXPAND: 'Розгорнути',
  EXPECTED_START_DATE_TOOLTIP: 'Дата отримання завдання на моб. пристрої',
  EXPECTED_START_DATE: 'Дата виконання',
  EXPORT_USERS_ERROR: 'Помилка експорту користувачів',
  EXPORT: 'Експортувати',
  FACT_DURATION_H: 'Фактична тривалість год',
  FACT_END_TIME_LONG: 'Фактичний час закінчення',
  FACT_END_TIME: 'Факт. Час закінчення',
  FACT_FINISH_DATE: 'Дата фактичного завершення',
  FACT_NUMBER_OF_PEOPLE: 'Факт. кількість людей',
  FACT_QUANTITY_CANNOT_BE_MORE_THAN_PLANNED: 'Фактична кількість не може бути більшою за планову.',
  FACT_QUANTITY_SHORT_UPDATED: ' Факт. кількість (змінене)',
  FACT_QUANTITY_SHORT: 'Факт. кількість',
  FACT_QUANTITY: 'Фактична кількість',
  FACT_START_DATE: 'Дата фактичного початку',
  FACT_START_TIME: 'Факт. Час початку',
  FACT_TIME: 'Факт. час',
  FAIL_TO_LOAD_AUTOTASKS: 'Не вдалось завантажити автозавдання',
  FAIL_TO_LOAD_FILTERS: 'Не вдалось завантажити фільтри',
  FAIL_TO_LOAD_ROUTES: 'Не вдалось завантажити маршрути',
  FAILED_CHECKPOINT_SCANNING: 'Неуспішне сканування мітки',
  FAILED_TO_CHECK: 'Не вдалось здійснити перевірку',
  FAILED_TO_DISPLAY_DEFECTS_FOR_THE_SELECTED_GROUP: 'Не вдалось відобразити дефекти за обраною групою.',
  FAQ: 'Питання та відповіді',
  FILL_ALL_REQUIRED_FIELDS: 'Заповніть усі обов`язкові поля.',
  FILL_DATA_TO_LOAD_REPORT: 'Вкажіть атрибути для завантаження звіту',
  FILTER_BY_EQUIPMENT: 'Фільтр за обладнанням',
  FILTRATION: 'Фільтрація',
  FIND_MEASUREMENT_UNIT: 'Знайти одиницю виміру',
  FINISH_DATE_CANNOT_BE_GREATER_THAN_START_DATE: 'Дата закінчення не може бути більше дати початку',
  FINISH_TIME: 'Час закінчення',
  FIRST_NAME: 'Ім`я',
  FIST_PAGE: 'Перша сторінка',
  FORM_HAS_ERROR: 'Форма містить помилку',
  FROM_SHORT: 'з',
  FROM: 'від',
  FULL_NAME: 'ПІБ',
  FUNCTIONAL_PLACEMENT: 'Функціональне розміщення',
  GENERAL_COMMENTS: 'Загальні коментарі',
  GENERAL_PARAMS: 'Загальні параметри',
  GENERAL_SETTINGS: 'Загальне налаштування',
  GO_BACK: 'Назад',
  GO_TO_DEFECTS_DICTIONARY_LIST: 'До перегляду довідника дефектів',
  GO_TO_DETAILS: 'Перейти до деталей',
  GO_TO_MEASUREMENT_REPORT_LIST: 'До рапортичок',
  GO_TO_PRESENCE_CONTROL: 'До контролю присутності',
  GO_TO_SETTINGS: 'До налаштування',
  H: 'Год',
  HELPER_TEXT_DAYS_INTERVAL: 'Не може бути більшим за різницю між полями «Дата закінчення» й «Дата Початку»',
  HELPER_TEXT_PLANDURATION_STARTDELAY_HOURSINTERVAL: 'З урахуванням відстрочки старту завдання «Час виконання» не повинен перевищувати «Інтервал»',
  HELPER_TEXT_PLANDURATION: 'Не може бути більшим за «Інтервал»',
  HELPER_TEXT_START_TIME_CURRENT_TIME: 'Повинно бути пізніше поточного часу',
  HELPER_TEXT_START_TIME_IS_EXPIRED: 'Будь ласка, перевірте «Час початку», він повинен бути пізніше поточного часу',
  HELPER_TEXT_STARTDELAY: 'Не може бути більше за «Інтервал»',
  HIDE_COMMENTS: 'Сховати коментарі',
  HIDE_EQUIPMENT_MEASUREMENTS: 'Сховати виміри за обладнанням',
  HIDE_EQUIPMENT: 'Сховати обладнання',
  HIERARCHY: 'Ієрархія',
  HIGH_PRIORITY: 'Високий пріоритет',
  HIGH: 'Високий',
  HISTORY: 'Історія',
  IDENTIFIED_DEFECTS: 'Виявлені дефекти',
  IDENTIFIED: 'Виявив',
  IMPORT_USERS_ERROR: 'Помилка імпорту користувачів',
  IMPORT: 'Імпортувати',
  IN_PROGRESS: 'У роботі',
  IN_RESERVE: 'У резерві/Пуск',
  IN_THE_TIME_RANGE: 'у часовому діапазоні',
  INACTIVE_KEY: 'Неактивний ключ',
  INDEFINITE: 'Безстроково',
  INFORMATION: 'Інформація',
  INITIAL_FACT_QUANTITY: 'Початкова фактична кількість',
  INPUT_EMPTY_VALUE: '—',
  INSPECTION_ATTRIBUTES: 'Атрибути обходу',
  INSPECTION_CREATED: 'Обхід успішно створено!',
  INSPECTION_CREATION: 'Створення обходу',
  INSPECTION_DELETED: 'Обхід успішно видалено!',
  INSPECTION_DELETING_MESSAGE: 'Впевнені, що хочете видалити обхід?',
  INSPECTION_DELETING: 'Видалення обходу',
  INSPECTION_ROUTE: 'Маршрут обходу',
  INSPECTION_STATUSES: 'Звіт обходів за період',
  INSPECTION_TITLE_NOMINATIVE: 'обхід',
  INSPECTION_UPDATED: 'Обхід успішно оновлено!',
  INSPECTION_VIEW: 'Перегляд Обходу',
  INSPECTIONS_LIST: 'Обходи',
  INSPECTIONS: 'Огляди(ни)',
  INTERVAL_DAYS: 'Інтервал (днів)',
  INTERVAL_HOURS: 'Інтервал (г)',
  INVALID_FORMAT: 'Невірний формат',
  INVALID_QUANTITY_SPECIFIED: 'Вказано некоректна кількість',
  IS_NOT_VISIBLE_IN_MOBILE_APP: 'Не відображається в Мобільному додатку',
  IS_REQUIRED_QR_CODE: 'Потрібне сканування QR коду',
  IS_TASK_ASSIGNED: 'На бригаду призначене завдання',
  IS_VISIBLE_IN_MOBILE_APP_SHORT: 'Відображення в МД',
  IS_VISIBLE_IN_MOBILE_APP: 'Відображається в Мобільному додатку',
  IT_MAY_HAVE_BEEN_DELETED_OR_AN_INCORRECT_ADDRESS_WAS_ENTERED: 'Можливо вона була видаленаабо ж введено неправильна адреса.',
  ITEMS_COUNT_LABEL_SHORT: 'шт',
  JOB: 'Завдання',
  KEY_ALREADY_EXISTS: 'Такий ключ вже існує.',
  KEY: 'Ключ',
  LAST_EDIT: 'Останнє редагування',
  LAST_EDITING_TIME: 'Останнє редагування',
  LAST_EXECUTION_RESULT: 'Результат останнього запуску',
  LAST_NAME: 'Прізвище',
  LAST_PAGE: 'Остання сторінка',
  LEVEL: 'Рівень',
  LINK_TO_LIBRARY: 'Шлях до бібліотеки',
  LIST_DISPLAY: 'Відображення списком',
  LIST_IS_EMPTY: 'Список порожній',
  MachinesAndMechanisms: 'Машини та механізми',
  MAKING_CHANGES_MAKE_SURE: 'При внесенні змін переконайтесь',
  MANUAL_DATA_ENTRY: 'Ручне введення даних',
  MAP_LINK: 'Посилання на карти',
  MATERIAL_VALUE_SHORT: 'ТМЦ',
  MATERIAL_VALUES_BY_OPERATIONS: 'ТМЦ по виконаним операціям',
  MATERIAL_VALUES_EDITING: 'Редакція ТМЦ',
  MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_ECONOMIST_FOR_CONFIRMATION: 'ТМЦ успішно узгоджені й відправлені Економісту ІЕС для подальшого узгодження.',
  MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_SAP_SYSTEM: 'ТМЦ успішно узгоджені й відправлені в систему SAP.',
  MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_SPECIALIST_FOR_CONFIRMATION: 'ТМЦ успішно узгоджені й відправлені Спеціалісту СДКР для подальшого узгодження.',
  MATERIAL_VALUES_WERE_SUCCESSFULLY_RETURNED_TO_THE_MASTER_FOR_REVISION: 'ТМЦ успішно повернуто Майстру на доопрацювання',
  MATERIALS: 'Матеріали',
  MAX_AMOUNT: 'Макс. кількість',
  MAX_DATE_VALIDATION_MESSAGE: 'Дата не повинна бути більшою за максимальну дату',
  MAX_DIST_TO_OBJECT: 'Макс. відстань до об`єкту',
  MAX_OPERATIONS_AMOUNT: 'Макс. кількість операцій',
  MAX_PHOTOS_COUNT: 'Макс. кількість фотографій',
  MAYBE_ROUTE_WAS_DELETED: 'Можливо даний маршрут було видалено.',
  MEASUREMENT_POINTS: 'Точки виміру',
  MEASUREMENT_POINTS_ACCUSATIVE: 'Точки виміру',
  MEASUREMENT_REPORT_LIST: 'Рапортички',
  MEASUREMENT_UNIT: 'Одиниця виміру',
  MEASUREMENTS_IMPOSSIBLE: 'Неможливо виконати виміри',
  MEASUREMENTS_UNITS: 'Одиниці виміру',
  MEASUREMENTS: 'Вимір',
  MEASUREMENTS_ACCUSATIVE: 'Вимір',
  METERS_UNIT_SHORT: 'м',
  MIN_ALLOWED_SIZE_SHORT: 'Мін. допустимий розмір',
  MIN_ALLOWED_SIZE: 'Мін. допустимий розмір (Пікс.)',
  MIN_AMOUNT: 'Мин. кількість',
  MIN_DATE_VALIDATION_MESSAGE: 'Дата не повинна бути раніше мінімальної дати',
  MIN: 'хв',
  NAME: 'Назва',
  NEW_ENTITY: 'Нова',
  NEW: 'Нові',
  NEXT_PAGE: 'Наступна сторінка',
  NO_ACCESS_MESSAGE: 'На жаль доступ до даного розділу обмежений для користувача з Вашою роллю.',
  NO_ACCESS_TITLE: 'Доступ обмежено!',
  NO_DEFECTS_FOUND: 'Дефекти не виявлено',
  NO_DEFECTS: 'Дефекти відсутні',
  NO_MEASUREMENTS_FOUND: 'Виміри не виявлено',
  NO_MEASUREMENTS: 'Показники відсутні',
  NO_OPERATION_KEYS_SETTINGS: 'Налаштування ключів операцій відсутні',
  NO_OPERATIONS_TO_CONFIRM: 'У вас немає операцій що очікують узгодження.',
  NO_OPTIONS: 'Немає опцій',
  NO_OVERLAY_TECH_OBJECTS_MESSAGE: 'В обраного Технічного місця відсутні підпорядковані технічні об`єкти.',
  NO_OVERLAY_TECH_OBJECTS_TITLE: 'Дія недоступна',
  NO_READINGS: 'Показання відсутні',
  NO_REPAIR_TYPES_SELECT_ANOTHER_EQUIPMENT: 'Для обраного обладнання немає виду ремонту необхідно обрати інше обладнання',
  NO_RESULTS: 'На жаль, збіги відсутні…',
  NO_ROUTE: 'Без маршруту',
  NO_TITLE: 'Назва відсутня',
  NO_WORKPLACES: 'Робочі місця відсутні',
  NODE: 'Вузол',
  NODE_ACCUSATIVE: 'Вузол',
  NOMINATION: 'Найменування',
  NON_STANDARD_OPERATIONS: 'Нестандартні операції',
  NOT_COMPLETED: 'Не виконано',
  NOT_EDITABLE_MESSAGE: 'Для даної мітки наявні невиконані завдання.',
  NOT_POSIBLE_CHANGE_REPORT_MESSAGE: 'Після відправки, зміна рапортички неможлива.',
  NOT_POSIBLE_CHANGE_TWICE_REPORT_MESSAGE: 'Після збереження, зміна рапортички неможлива.',
  NOT_STANDARD_MULTIPLE: 'Нестандартні',
  NOT_STANDARD_TASK_DESCRIPTION: 'Опис нестандартного завдання',
  NOT_STANDARD_TASK_TYPE: 'Нестандартне завдання',
  NOT_VALID_SHIFT_MESSAGE: 'На даний момент сформувати звіт можливо тільки для',
  NOTHING_FOUND: 'Нічого не знайдено',
  NUMBER_OF_TAGS: 'Мітки (кількість)',
  NUMBER: 'Номер',
  NUMERIC_INPUT: 'Числове введення',
  OBTAINING_APPROVALS: 'Отримання допусків',
  OCCUPATIONAL_SAFETY: 'Охорона праці',
  ON_APPROVAL: 'На затвердженні',
  ON_CONTROL: 'На контролі',
  ON_RESCHEDULING: 'На перепланування',
  ONLY_CYRILLIC_SYMBOLS: 'Допускаються тільки кириличні символи!',
  ONLY_EDITED: 'Тільки відредаговані',
  OPEN: 'Відкрити',
  OPERATION_CONFIRMATION: 'Підтвердження операції',
  OPERATION_KEY_ADDING: 'Додавання ключа операцій',
  OPERATION_KEY_ATTRIBUTES: 'Атрибути ключа операції',
  OPERATION_KEY_EDITING: 'Редагування ключа операцій',
  OPERATION_KEY_IS_USED_IN_VOLUMES_PARAMS: 'Даний ключ використовується в параметрах фіз. об`єма.',
  OPERATION_KEY_SUCCESSFULLY_ADDED: 'Ключ операції успішно додано!',
  OPERATION_KEY: 'Ключ операції',
  OPERATION_KEYS: 'Ключі операцій',
  OPERATION_ON_PAUSE: 'Операція на паузі',
  OPERATION_REJECTED: 'Операцію відхилено',
  OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_ECONOMIST_FOR_CONFIRMATION: 'Операція підтверджена й відправлена  Економісту ІЕС для підтвердження.',
  OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_SAP_SYSTEM: 'Операція підтверджена й відправлена в систему SAP',
  OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_SPECIALIST_FOR_CONFIRMATION: 'Операція буде підтверджена й відправлена Спеціалісту СДКР для підтвердження.',
  OPERATION: 'Операція',
  OPERATIONS_CHOISE: 'Вибір операцій',
  OPERATIONS_FROM_TC: 'Операції з ТК',
  OPERATIONS_IN_DONE_STATUS: 'Виконані операції',
  OPERATIONS: 'Операції',
  ORDER_NUMBER: 'Номер замовлення ТОРО',
  ORDER_REQUIRED_IS_OFF: 'Обов`язковий порядок проходження маршруту вимкнено',
  ORDER_REQUIRED_IS_ON: 'Ввімкнений  обов`язковий порядок проходження маршруту',
  ORDER_REQUIRED_SHORT: 'Обов`язковий порядок',
  ORDER_REQUIRED: 'Обов`язковий порядок',
  ORDER_TYPE: 'Вид замовлення',
  ORG_UNIT_CODE_SHORT: 'Код ОО',
  ORG_UNIT_NOT_PROVIDED: 'Підприємство не вказано',
  ORG_UNIT_SHORT: 'Орг. одиниця (ОО)',
  OSR_NAME: 'Назва ОСР',
  OSR_SETTINGS: 'Налаштування для ОСР',
  PAGE_NOT_FOUND: 'Сторінка не знайдена',
  PAGES_COUNT_LABEL: 'сторінок',
  PARALLEL_OPERATION_COUNT_RANGE: 'Значення повинно бути від 1 до 100',
  PARAMETER_ADD: 'Додати параметр',
  PARAMETER_CREATE: 'Створення параметру',
  PARAMETER_DELETE: 'Видалити параметр',
  PARAMETER_EDIT: 'Редагувати параметр',
  PARAMETER_EDITING: 'Редагування параметра',
  PARAMETER_GENITIVE: 'параметра',
  PARAMETER_NAME: 'Назва параметру',
  PARAMETER_TYPE: 'Тип параметра',
  PARAMETER: 'Параметр',
  PARTIALLY_DONE: 'Частково виконана',
  PAUSE_REASON_ATTRIBUTES: 'Атрибути причини постановки на паузу',
  PAUSE_REASON_CREATION: 'Створення причини постановки на паузу',
  PAUSE_REASON_EDITING: 'Редакція причини постановки на паузу',
  PAUSE_REASON: 'Причина постановки на паузу',
  PAUSE_REASONS: 'Причини постановки на паузу',
  PAUSE: 'На паузі',
  PAUSING_A_TASK: 'Постановка завдання на паузу',
  PENDING: 'В очікуванні',
  PEOPLE_CUT: 'люд',
  People: 'Люди',
  PERCENT_SYMBOL: '%',
  PERFORMER: 'Виконавець',
  PERFORMERS: 'Виконавці',
  PERIOD_FINISH: 'Кінець періоду',
  PERIOD_START: 'Початок періоду',
  PERSONNEL_NUMBER: 'Табельний номер',
  PHOTO_IS_NOT_REQUIRED: 'Не потребує фотофіксації',
  PHOTO_IS_REQUIRED: 'Потребує фотофіксації',
  PHOTO_REQUIREMENT: 'Необхідність фотофіксації',
  PHOTOS_PROCESSING_AND_UPLOADING: 'Обробка та завантаження фото',
  PHOTOS_QUALITY_SHORT: 'Якість фотографій',
  PHOTOS_QUALITY: 'Якість фотографій (%)',
  PHOTOS: 'Фотографії',
  PIXELS: 'Пікселів',
  PL_DEPARTMENT: 'Департамент',
  PLAN_TIME: 'Плановий час',
  PLANNED_COMPLETION_DATE: 'Плановий час завершення',
  PLANNED_DEFECTS_PRESENCE: 'Наявність планових дефектів',
  PLANNED_DURATION: 'Планова тривалість',
  PLANNED_END_TIME: 'Плановий час завершення',
  PLANNED_LEAD_TIME: 'Плановий час виконання',
  PLANNED_MEASUREMENTS_PRESENCE: 'Наявність планових вимірів',
  PLANNED_NUMBER_OF_PEOPLE: 'Плануєма кількість людей',
  PLANNED_QUANTITY: 'Планова кількість',
  PLANNED_START_DATE: 'Планова дата початку',
  PLANNED_START_TIME: 'Плановий час початку',
  PLANNED_TIME: 'Планований час',
  PLANNER_TASK_ATTRIBUTES: 'Атрибути завдання',
  PLANNER_TASK_CREATION: 'Створення завдання',
  PLANNER_TASK_SUCCESSFULLY_CREATED: 'Завдання планувальника успішно створено',
  PLANNER_TASKS: 'Завдання планувальника',
  PLANNER: 'Планувальник',
  PLANT_CODE: 'Код заводу',
  PLANTS_STORAGES_CODES: 'Коди заводу/складу',
  POSITION_CODE: 'Код позиції',
  POSITION_NAME: 'Назва позиції',
  POSITION: 'Посада',
  POSITIONS_IN_THE_DICTIONARY: 'Позиції у довіднику',
  POWER_UNIT_STATE: 'Стан енергоблоку',
  POWER_UNIT: 'Енергоблок',
  PRESENCE_CONTROL_PARAMS: 'Параметри контролю присутності',
  PRESENCE_CONTROL: 'Контроль присутності',
  PRETEXT_FOR: 'по',
  PREVIOUS_ACTIONS: 'Попередні дії',
  PREVIOUS_PAGE: 'Попередня сторінка',
  PRIORITY: 'Пріоритет',
  PROBLEMS: 'Проблеми',
  PROCEED: 'Продовжити',
  PROCESS_REJECTION_REASON: 'Причина відмови від виконання',
  PROCESSED: 'Обробив',
  PRODUCER: 'Виробник',
  PROVIDE_OPERATION_RETURNING_REASON: 'Вкажіть причину повернення операції',
  QR_CODE_SCANNING: 'Сканування QR коду',
  QR_CODE: 'QR код',
  QR: 'QR',
  QUANTITY_UPDATED_BY_MASTER: 'Кількість вказана Майстром',
  RE_REGISTRATION_SHORT: 'Повт. регістр.',
  REASON_COMMENT: 'Причина/коментар',
  REASON_FOR_REFUSAL_TO_EXECUTE: 'Причина відмови від виконання',
  REASON_SUCCESSFULLY_ADDED: 'Причина успішно додана!',
  REASON_WITH_SAME_CODE_EXISTS: 'Причина з таким кодом вже існує.',
  REASON: 'Причина',
  RECORD_IS_NOT_UNIQUE: 'Запис з такими параметрами вже існує.',
  RECORD_SUCCESSFULLY_ADDED: 'Запис успішно доданий!',
  REJECT: 'Відхилити',
  REJECTED_ENTITY: 'Відхилено',
  REJECTED: 'Відхилені',
  REJECTING_DEFECT: 'Відхилення дефекту',
  REJECTION_REASON: 'Причина відхилення',
  REMOVAL_IS_PROHIBITED: 'Видалення заборонено.',
  REMOVE_APPOINTMENT: 'Відмінити призначення',
  REMOVE_FROM_LIST: 'Видалити зі списку',
  REPAIR_TASK: 'Завдання на ремонт',
  REPORT_ALREADY_EXISTS_MESSAGE: 'Рапортичка за такими атрибутами й періоду побудови вже створена. Повторне створення  неможливе.',
  REPORT_ATTRIBUTES: 'Атрибути звіту',
  REPORT_BUILDING_ERR_MESSAGE: 'Помилка валідації під час формування «рапортички»',
  REPORT_BUILDING: 'Побудова рапортички',
  REPORT_CAN_BE_EDITET_ONCE: 'Змінити рапортичку можна лише 1 раз.',
  REPORT_CREATION_IMPOSSIBLE: 'Створення рапортички неможливо.',
  REPORT_CREATION: 'Створення рапортички',
  REPORT_EDITING: 'Редагування рапортички',
  REPORT_IS_NOT_EDITED: 'Не вдалося відредагувати рапортичку',
  REPORT_IS_NOT_SAVED: 'Не вдалося зберегти рапортичку',
  REPORT_IS_READY_TO_LOAD: 'Звіт готовий до завантаження!',
  REPORT_LONG: 'Рапортичка',
  REPORT_NO_DATA_MESSAGE: 'За вхідними критеріями в БД відсутні дані для побудови рапортички',
  REPORT_PERIOD_SHORT: 'Період побудови',
  REPORT_PERIOD: 'Період побудови звіту',
  REPORT_SAVING: 'Збереження рапортички',
  REPORT_SUCCESSFULLY_CREATED: 'Рапортичка успішно створена!',
  REPORT_TYPE: 'Тип звіту',
  REPORT_VALIDATION_MESSAGE: 'Помилка валідації під час збереження «рапортички».',
  REPORT_WILL_BE_SEND_MESSAGE: 'Рапортичка буде отправлена до Начальника зміни КТЦ.',
  REPORT: 'Звіт',
  REPORTS: 'Звіти',
  REQUEST_BRIGADES_LIST_ERROR: 'Під час завантаження списку бригад виникла помилка',
  REQUEST_DEFAULT_ERROR: 'Виникла помилка',
  REQUEST_LOAD_DEPARTMENTS_ERROR: 'Під час завантаження департаментів виникла помилка',
  REQUEST_LOAD_LIST_ERROR: 'Під час завантаження списку виникла помилка',
  REQUEST_LOAD_POWER_UNITS_ERROR: 'Під час завантаження енергоблоків виникла помилка',
  REQUEST_LOAD_TECH_OBJECTS_ERROR: 'Під час завантаження технічних об`єктів виникла помилка',
  REQUEST_LOAD_TECHNICAL_PLACES_ERROR: 'Під час завантаження ТМ виникла помилка',
  REQUEST_ORG_UNITS_DICTIONARY_ERROR: 'Помилка завантаження довідника ОО',
  REQUEST_REASONS_DICTIONARY_ERROR: 'Помилка завантаження довідника причин',
  REQUEST_USER_ROLES_DICTIONARY_ERROR: 'Помилка завантаження довідника ролей користувачів',
  REQUIRED_FIELD_FOR_DEVICE: 'Обов`язковий параметр на присторої',
  REQUIRED: 'Обов`язкове поле',
  REREGISTRATION_ALLOWED: 'Дозволено для повторної реєстрації',
  REREGISTRATION_NOT_ALLOWED: 'Заборонено для повторної реєстрації',
  RESCHEDULE: 'Перепланувати',
  RESCHEDULED: 'Перепланована',
  RESERVE: 'Резерв',
  RESOURCES: 'Ресурси',
  RESPONSIBLE: 'Відповідальний',
  RESTRICTED: 'заборонено.',
  RETURN: 'Повернути',
  RETURNED: 'Повернені',
  RETURNING_OPERATIONS_TO_THE_MASTER: 'Повернення операції Майстру',
  REVISION_FOR_MASTER: 'Майстру на допрацювання',
  ROLE: 'Роль',
  ROLES: 'Ролі',
  ROUTE_ACCUSATIVE: 'маршруту',
  ROUTE_AND_OPERATIONS: 'Маршрут й операції',
  ROUTE_ATTRIBUTES: 'Атрибути маршруту',
  ROUTE_CREATION: 'Створення маршруту',
  ROUTE_EDITING: 'Редагування маршруту',
  ROUTE_NAME: 'Назва маршруту',
  ROUTE_TAGS: 'Мітки в маршруті (№)',
  ROUTE_TECH_OBJECTS: 'Обладнання маршруту',
  ROUTE_VIEW: 'Перегляд маршруту',
  ROUTE: 'Маршрут',
  ROUTES: 'Маршрути',
  RUN: 'Запустити',
  RUNTIME: 'Тривалість виконання',
  SAP_CODE_PARAMETER: 'Код параметру (SAP)',
  SAP_CODE: 'SAP код',
  SAP_WORK_PLACE: 'Робоче місце із SAP',
  SAVE: 'Зберегти',
  SCHEDULED_INSPECTIONS: 'Планові огляди',
  SCHEDULED_MEASUREMENTS: 'Планові виміри',
  SEARCH_BY_NAME: 'Пошук за назвою',
  SEARCH_STAFF: 'Пошук співробітника',
  SECTOR: 'Ділянка',
  SELECT_DATE: 'Обрати дату виконання',
  SELECT_DEFECTS_GROUP: 'Оберіть групу дефектів для відображення',
  SELECT_EMPLOEES_FROM_LIST: 'Оберіть зі списку співробітників:',
  SELECT_EQUIPMENT_AND_TYPE_OF_REPAIR_TO_DISPLAY_OPERATIONS: 'Оберіть обладнання й Вид ремонту в Атрибутах завдання для відображення операцій',
  SELECT_PERFORMERS_AND_APPOINT_A_RESPONSIBLE: 'Оберіть виконавців і призначте відповідального',
  SELECT_POWER_UNIT: 'Оберіть енергоблок',
  SELECT_PRODUCER: 'Призначте виробника',
  SELECT_VALUE: 'Оберіть значення',
  SELECT: 'Оберіть',
  SELECTION_FROM_THE_DIRECTORY: 'Вибір із довідника',
  SEND_TO_WORK: 'Відправити в роботу',
  SENDING_TO_WORK: 'Відправлення в роботу',
  SERVER_IS_NOT_AVAILABLE: 'Сервер недоступний.',
  SET_ON_PAUSE: 'На паузу',
  SETTINGS_BY_PHOTO_TYPE: 'Параметри за типом фото',
  SETTINGS: 'Налаштування',
  SHAREPOINT_GENERAL_SETTINGS: 'Загальні параметри при відправленні в SharePoint',
  SHIFT_ACCEPTANCE: 'Приймання зміни',
  SHIFT_TYPE: 'Тип зміни',
  SHIFT: 'Зміна',
  SHOULD_CHOOSE_BRIGADE_STAFF: 'Оберіть склад бригади зі списку співробітників і призначте бригадира',
  SHOULD_CLEAN_STATE_MESSAGE: 'Зміна значення в даному полі призведе до видаленню збережених операцій.',
  SHOULD_ENTER_BRIGADE_TITLE: 'Введіть назву бригади',
  SHOW_COMMENTS: 'Показати коментарі',
  SHOW_EQUIPMENT_MEASUREMENTS: 'Показати виміри обладнання',
  SHOW_EQUIPMENT: 'Показати обладнання',
  SHOW_MATERIAL_VALUES: 'Відобразити ТМЦ',
  SPECIFY_A_TASK_DUE_DATE: 'Вкажіть дату виконання завдання',
  SPECIFY_DATA_TYPE: 'Вкажіть тип даних',
  SPECIFY_FIELD_TYPE_AND_NAME: 'Вкажіть тип поля та назву',
  SPECIFY_MEASUREMENT_UNIT: 'Вкажіть одиницю виміру',
  STAFF_LIST: 'Список співробітників',
  STAFF: 'Склад',
  STANDARD_MULTIPLE: 'Стандартні',
  STANDARD_PRIORITY: 'Стандартний пріоритет',
  STANDARD_TASK_TYPE: 'Стандартне завдання',
  STANDARD: 'Стандартний',
  START_DATE_CANNOT_BE_GREATER_THAN_THE_END_DATE: 'Дата початку не може бути більше дати закінчення',
  START_DATE_CANNOT_BE_PAST_TENSE: 'Дата початку не може бути минулим часом',
  START_DATE: 'Дата початку',
  START_IN_MIN: 'Старт через (хв)',
  START_TIME_AND_PLANNED_TASK_EXECUTION_TIME: 'Час початку й плановий час виконання завдання',
  START_TIME_MUST_NOT_BE_LESS_THAN_THE_END_TIME: 'Час початку не повинен бути менше часу закінчення',
  START_TIME: 'Час початку',
  STATE: 'Стан',
  STATISTICS: 'Статистика',
  STATUS: 'Статус',
  STORAGE_CODE: 'Код складу ТМЦ',
  SUCCESSFUL_QR_CODE_SCANNING: 'Успішне сканування QR коду',
  SUCCESSFULLY: 'Успішно',
  SUR_NAME: 'По батькові',
  SYMBOLS: 'символи',
  SYNC_IS_IN_PROGRESS: 'Йде синхронізація',
  SYSTEM_PAUSE_REASON: '(Системна)',
  TAG_ACCUSATIVE: 'мітку',
  TAG_ADDING: 'Додавання мітки',
  TAG_CHOOSING: 'Вибір мітки',
  TAG_CODE: 'Код мітки',
  TAG_CREATION: 'Створення мітки',
  TAG_EDITING: 'Редагування мітки',
  TAG_GENITIVE: 'мітки',
  TAG_NAME: 'Назва мітки',
  TAG_NFC_CODE: 'Код NFC мітки',
  TAG_NUMBER: 'Номер мітки',
  TAG_VIEW: 'Перегляд мітки',
  TAG: 'Мітка',
  TAGS: 'Мітки',
  TASK_ACCUSATIVE: 'завдання',
  TASK_ATTRIBUTES: 'Атрибути завдання',
  TASK_CREATION_INTERVAL: 'Інтервал створення завдання',
  TASK_CREATION: 'Створення завдання',
  TASK_DESCRIPTION: 'Опис завдання',
  TASK_EXECUTION_PERIOD: 'Період виконання Завдання',
  TASK_HAS_ALREADY_BEEN_TAKEN_INTO_OPERATION: 'Це завдання вже взято в роботу',
  TASK_NAME_ALREADY_EXISTS: 'Завдання з такою назвою вже існує.',
  TASK_REJECTION_REASON: 'Причина відхилення завдання',
  TASK_RESCHEDULING: 'Перепланування завдання',
  TASK_ROUTE_SUCCESSFULLY_CREATED: 'Маршрут успішно створений!',
  TASK_ROUTE_SUCCESSFULLY_REMOVED: 'Маршрут успішно видалений!',
  TASK_SCHEDULE: 'Розклад завдання',
  TASK_SOURCE: 'Джерело завдання',
  TASK_STATE: 'Стан завдання',
  TASK_STATUS: 'Статус завдання',
  TASK_SUCCESSFULLY_ASSIGNED: 'Завдання успішно передано в роботу!',
  TASK_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_COMPLETED_TAB: 'Завдання успішно підтверджено та надіслано у вкладку “Завершені”',
  TASK_SUCCESSFULLY_CREATED: 'Завдання успішно створено!',
  TASK_SUCCESSFULLY_DELETED: 'Завдання успішно видалено',
  TASK_SUCCESSFULLY_REJECTED: 'Завдання успішно відхилено та передано Ініціатору',
  TASK_SUCCESSFULLY_REMOVED: 'Завдання успішно видалено!',
  TASK_SUCCESSFULLY_RESCHEDULED: 'Завдання успішно переплановано',
  TASK_TITLE: 'Назва завдання',
  TASK_TYPE: 'Тип завдання',
  TASK_VIEW: 'Перегляд завдання',
  TASK_WILL_BE_MOVED_TO_A_TAB: 'Завдання буде переміщено у вкладку',
  TASK_WITH_DEFECTS: 'Виявлені дефекти!',
  TASK_WITHOUT_DEFECTS: 'Дефекти не виявлені',
  TASKS_PER_SHIFT: 'Завдань на зміну',
  TASKS: 'Завдання',
  TECHNICAL_PLACE_SHORT: 'ТМ',
  TECHNICAL_PLACE: 'Технічне місце',
  TEMP: 'Тимчасово',
  TEXT_INPUT: 'Текстове введення',
  THE_DEFECT_WILL_BE_SENT_TO_THE_IN_PROGRESS_TAB: 'Дефект буде відправлено у вкладку “В роботі”.',
  THE_END_TIME_MUST_NOT_BE_GREATER_THAN_THE_START_TIME: 'Час закінчення не повинен бути більшим за час початку',
  THE_INTERVAL_OF_DAYS_BETWEEN_RECEIVING_A_TASK_ON_A_MOBILE_DEVICE: 'Інтервал днів між отриманням задачі на моб. пристрої',
  THE_RECOMMENDED_TIME_FOR_COMPLETING_THE_TASK_ON_THE_DEVICE: 'Рекомендований час виконання завдання на моб. пристрої',
  THE_TASK_WILL_BE_RESCHEDULED: 'Завдання буде переплановане.',
  THE_TIME_AT_THE_END_OF_WHICH_THE_TASK_STARTS_ON_THE_DEVICE: 'Час після закінчення якого завдання стартує на моб. пристрої',
  THERE_ARE_NO_MATERIAL_VALUES: 'ТМЦ відсутні',
  THERE_ARE_NO_PAUSE_REASONS: 'Причини постановки на паузу відсутні',
  THERE_ARE_OUTSTANDING_TASKS_ALONG_THIS_ROUTE: 'За даним маршрутом є невиконані завдання.',
  THERMAL_POWER_PLANT: 'ТЕС',
  THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_ON_APPROVAL_TAB: 'Дана операція відображатиметься у вкладці “На затвердженні”',
  THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_RETURNED_TAB: 'Дана операція відображатиметься у вкладці “Повернені”',
  TILE_DISPLAY: 'Відображення плиткою',
  TIME: 'Час',
  TITLE: 'Назва',
  TO_REPORT_CATEGORY_SELECT: 'До вибору категорії звіту',
  TO_ROUTE_VIEW: 'До перегляду маршруту',
  TO_ROUTES_LIST: 'До списку маршрутів',
  TO_TAG_VIEW: 'До перегляду мітки',
  TO_TAGS_LIST: 'До списку міток',
  TO_TASK_LIST: 'До списку завдань',
  TO_TASK_VIEW: 'До перегляду завдання',
  TO_USER_VIEW: 'До перегляду даних користувача',
  TO_USERS_LIST: 'До списку користувачів',
  TO: 'до',
  TOU_CAN_ADD_REASON: 'Ви можете додати причину.',
  TRANSPORT: 'Транспорт',
  TYPE_OF_REPAIR: 'Вид ремонту',
  TYPE: 'Тип',
  UNASSIGN_RESPONSIBLE: 'Зняти ознаку відповідальності',
  UNASSIGNED: 'Непризначені',
  UNCOLLAPSE_ALL: 'Закрити все',
  UNKNOWN: 'Невідомо',
  UNSUCCESSFUL_QR_CODE_SCANNING: 'Неуспішне сканування QR коду',
  UPDATE_DATE: 'Дата й час зміни',
  UPDATED_ON: 'Оновлено',
  UPLOADING_DOCUMENTS: 'Завантаження документів',
  URL_IS_NOT_VALID: 'Дане посилання не валідне',
  USER_ATTRIBUTES: 'Атрибути користувача',
  USER_IS_ACTIVE: 'Користувач активний',
  USER_IS_BLOCKED: 'Користувач заблокований',
  USER_ROLE: 'Роль користувача в системі',
  USER_SEARCH_HELPER: 'Для пошуку користувачів скористуйтеся пошуковим рядком або блоком фільтрації.',
  USER_SEARCH: 'Пошук користувачів',
  USER_SUCCESSFULLY_CREATED: 'Користувач успішно доданий',
  USER_TEXT: 'Користувальницька назва',
  USERS_DICTIONARIES: 'Користувальницькі довідники',
  USERS_IMPORT_ERROR_REPORT: 'Під час імпорту виникли помилки. Звіт завантажений.',
  USERS_IMPORTED_SUCCESSFULLY: 'Користувачі успішно імпортовані.',
  USERS: 'Користувачі',
  VIEW_A_PHOTO: 'Переглянути фото',
  VIEW_A_TASK: 'Переглянути завдання',
  VIEW_DEFECT: 'Перегляд дефекту',
  VIEW_PROFILE: 'Дивитися профіль',
  VOLUME_PAGE_TITLE: 'Налаштування фіз. об`єму',
  VOLUME_PARAMETER_IS_ABSENT: 'Параметри об`єму відсутні.',
  VOLUME_TAB: 'Вкладка “Об`єм”',
  VOLUMES_SHORT: 'Фіз. об`єм',
  WAS: 'Було',
  WITHOUT_EQUIPMENT_UNIT: 'Без вузла',
  WORK_CONFIRMATION: 'Підтвердження робіт',
  WORK_PLACE_ADDING: 'Додавання робочого місця',
  WORK_PLACE_ATTRIBUTES: 'Атрибути робочого місця',
  WORK_PLACE_EDITING: 'Редагування робочого місця',
  WORK_PLACE: 'Робоче місце',
  WORK: 'Робота',
  WORKPLACE_DELETION: 'Видалення робочого місця',
  WORKPLACE_DUPLICATE_CODE: 'Робоче місце з таким кодом вже існує.',
  WORKPLACE_SUCCESSFULLY_ADDED: 'Робоче місце успішно додано!',
  WORKPLACE_SUCCESSFULLY_DELETED: 'Робоче місце успішно видалено!',
  WORKPLACES: 'Робочі місця',
  YOU_CAN_ADD_OPERATION_KEY: 'Ви можете додати ключ операції',
  YOU_CAN_ADD_WORKPLACE: 'Ви можете додати робоче місце.',
  YOU_CAN_SWITCH_AT_ANY_TIME: 'Ви можете переключитись у будь-який час.',
  YOU_NEED_CHANGE_TMC_TO_CONFIRM: 'Для підтвердження потрібно змінити ТМЦ',
  TPS_REPAIR_BUSINESS_NAME: 'ТЕС.Ремонты',
  TPS_INSPECTION_BUSINESS_NAME: 'ТЕС.Обходи',
  COAL_BUSINESS_NAME: 'Вугілля',
  PL_BUSINESS_NAME: 'Мережі',
  FOREMAN_ROLE_NAME: 'Начальник цеху',
  HEAD_MASTER_ROLE_NAME: 'Ст. майстер',
  MASTER_ROLE_NAME: 'Майстер',
  COAL_MASTER_ROLE_NAME: 'Гірський майстер',
  PERFORMER_ROLE_NAME: 'Виконавець',
  MECHANIC_ROLE_NAME: 'Механік',
  HEAD_MECHANIC_ROLE_NAME: 'Головний механік',
  MANAGER_ROLE_NAME: 'Менеджер',
  SUPER_ADMIN_ROLE_NAME: 'Суперадмін',
  BOILER_OPERATOR_ROLE_NAME: 'Машиніст-обхідник з котельного обладнання',
  BOILER_OPERATOR_GZU_ROLE_NAME: 'Машиніст-обхідник з котельного обладнання (ГЗУ)',
  BOILER_OPERATOR_SHBM_ROLE_NAME: 'Машиніст-обхідник з котельного обладнання (ШБМ)',
  BOILER_OPERATOR_RG_ROLE_NAME: 'Машиніст-обхідник з котельного обладнання (Ряд Г)',
  POWER_UNIT_OPERATOR_ROLE_NAME: 'Машиніст енергоблоку',
  SENIOR_POWER_UNIT_OPERATOR_ROLE_NAME: 'Старший машиніст енергоблоку',
  SHIFT_SUPERVISOR_ROLE_NAME: 'Начальник зміни',
  SHIFT_SUPERVISOR_KTC_ROLE_NAME: 'Начальник зміни КТЦ',
  TURBINE_OPERATOR_ROLE_NAME: 'ашиніст-обхідник з турбінного обладнання',
  ADMINISTRATOR_OK_ROLE_NAME: 'Адміністратор ОК',
  ADMINISTRATOR_OCP_ROLE_NAME: 'Адміністратор Підприємства',
  PLANNER_ROLE_NAME: 'Плановик',
  SPECIALIST_SDKR_ROLE_NAME: 'Фахівець СДКР',
  ECONOMIST_IETS_ROLE_NAME: 'Економіст ИЭС',
  UNKNOWN_DEFECT_STATUS: 'Невідомо',
  NEW_DEFECT_STATUS: 'Новий',
  IN_PROGRESS_DEFECT_STATUS: 'В роботі',
  REJECTED_DEFECT_STATUS: 'Відхилений',
  ARCHIVED_DEFECT_STATUS: 'В архіві',
  PENDING_DEFECT_STATUS: 'Чекає на виконання',
  REJECTED_DEFECT_STATUS_SHORT: 'Відхилений',
  COMPLETED_DEFECT_STATUS: 'Виконаний',
  PERFORMS_DEFECT_STATUS: 'Виконується',
  SCHEDULED: 'Заплановано',
  CUSTOM_ACTION: 'Нестандартне завдання',
  FUll_NAME: 'Ф.И.О.',
  // MAX_REDUCE_START_TIME: 'Макс. тривалість для корегування початку',
  MAX_REDUCE_START_TIME: 'Макс. корегування початку',
  // MAX_REDUCE_END_TIME: 'Макс. тривалість для корегування закінчення'
  MAX_REDUCE_END_TIME: 'Макс. корегування закінчення',
  HOURS: {
    0: '0 годин',
    1: '1 година',
    2: '2 години',
    3: '3 години',
    4: '4 години',
    5: '5 годин',
    6: '6 годин',
    7: '7 годин',
    8: '8 годин',
    9: '9 годин',
    10: '10 годин',
    11: '11 годин',
    12: '12 годин'
  }
};
export default TRANSLATIONS_UA;
