import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useHistory, withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import DataTable from 'Common/components/DataTable/DataTable';
import { tableConfig } from 'Business/plant/config/presenceControlTableConfig';
import Header from 'Common/widgets/Layout/Header/Header';
import EmptyResultsBlock from 'Common/widgets/EmptyResultsBlock';
import { SETTINGS, PRESENCE_CONTROL } from 'Shared/constants/routes';

const PresenceControlList = ({ theme }) => {
  const { t } = useTranslation();
  const translatedTableConfig = tableConfig(t);
  const {
    presenceControlStore: {
      list,
      loadList,
      isLoading,
      isListLoaded,
    } = {},
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const history = useHistory();

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadList().catch(() => showDefaultErrorNotification());
  }, []);

  const onRowClickHandler = (event, row = {}) => {
    const { id, isUpdating } = row;
    if (isUpdating) return;
    history.push({
      pathname: `${PRESENCE_CONTROL}/edit/${id}`,
      state: { fromPath: PRESENCE_CONTROL }
    });
  };

  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
  } = theme;
  const tableBodyHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px)`;

  const showEmptyResultsBlock = isEmpty(list) && isListLoaded;
  const showData = !isEmpty(list);

  const goToSettings = () => history.push({ pathname: SETTINGS });

  return (
    <PaperLayout>
      {isLoading && <ProgressBar />}
      <Header
        isActiveGoBack
        title={t('PRESENCE_CONTROL')}
        titleGoBack={t('GO_BACK')}
        handleGoBack={goToSettings}
        style={{ borderBottom: 'none' }}
      />
      <div>
        {showEmptyResultsBlock && <EmptyResultsBlock />}
        {showData && (
        <DataTable
          isVirtualized
          tableBodyHeight={tableBodyHeight}
          data={list}
          titleSingle={t('TASK_ACCUSATIVE')}
          columns={translatedTableConfig.columns}
          onRowClick={onRowClickHandler}
          actions={translatedTableConfig.actions}
          dataTestRowSuffixEntityName="id"
        />
        )}
      </div>
    </PaperLayout>
  );
};

PresenceControlList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
    pageSearchBlockHeight: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(PresenceControlList)));
